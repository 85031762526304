import React, { useState, useEffect } from "react";
import MyContext from "./MyContext";
import axiosURL from "../axios";
import axios from "axios";

export function GetCurrentUser() {
  const userString = sessionStorage.getItem("currentClient");
  if (userString) {
    try {
      return JSON.parse(userString);
    } catch {
      return null;
    }
  }
  return null;
}

const MyProvider = ({ children }) => {
  const [user, setUser] = useState(GetCurrentUser());
  const [data, setData] = useState(null);
  const [displayData, setdisplayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sidebarError, setSideBarError] = useState(false);
  const [threadID, setThreadID] = useState(null);
  const [theme, setTheme] = useState("");
  const [backendUrl, setBackendUrl] = useState("");
  const [demoImage, setDemoImage] = useState(false);
  const [deploymentStatus, setDeploymentStatus] = useState(false);
  const [firstRun, setFirstRun] = useState(false);
  const [firstRunData, setFirstRunData] = useState([]);
  const [clientId, setClientId] = useState("");
  const [jsonDataFirstRun, setJsonDataFirstRun] = useState("");
  const [imageCall, setImageCall] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [internalError, setInternalError] = useState({
    status: false,
    message: "",
  });

  React.useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  const setSignedInUser = (user) => {
    setUser(user);
    sessionStorage.setItem("currentClient", JSON.stringify(user));
  };

  const resetUser = () => {
    setUser(null);
    sessionStorage.removeItem("currentClient");
  };

  const setThreadIDFunction = (value) => {
    setThreadID(value);
  };

  const setDisplayDataFunction = (d) => {
    setdisplayData([...d]);
  };

  const setLoadingFunction = (value) => {
    setLoading(value);
  };

  const sideBarErrorFun = (value) => {
    setSideBarError(value);
  };

  const setThemeFunction = (value) => {
    setTheme(value);
    localStorage.setItem("mode", value);
  };

  const setBackendUrlFunction = (value) => {
    setBackendUrl(value);
  };

  const setDeploymentStatusFunction = (value) => {
    setDeploymentStatus(value);
  };

  const setFirstRunFunction = (value) => {
    setFirstRun(value);
  };

  const setDemoImageFuntion = (value) => {
    setDemoImage(value);
  };

  const setFirstRunDataFunction = (value) => {
    setFirstRunData(value);
  };
  const setClientIdFunction = (value) => {
    setClientId(value);
  };

  useEffect(() => {
    const fetchBackendConfig = async () => {
      try {
        const res = await axios.get("/weatherforecast/getConfigurationValues");
        setClientIdFunction(res.data.apP_CLIENT_ID);
        setBackendUrl(res.data.backend);
        await fetchData(res.data.backend);
      } catch (error) {
        console.log("Error while fetching Backend URL:-", error);
      }
    };

    const fetchData = async (backendUrl) => {
      let respFirstRun;
      try {
        if (user !== null) {
          setLoading(true);

          // const res1 = await axios.get(`${backendUrl}/api/ChatThreads/`);
          // console.log("res1:-", res1);

          // let res = await axios.get(
          //   `${backendUrl}/api/ChatThread/uniqueId/${user.uniqueId}`
          // );

          const res = await axios.get(
            `${backendUrl}/api/ChatThreads/ByOwnerId/7F03D6AA-356B-4F8D-ABA4-04082EBC0A0E`
          );

          // respFirstRun = await axios.get(
          //   `${backendUrl}/api/Login/FirstrunFileExist`
          // );

          // if (!respFirstRun.data.fileExists) {
          //   const obj = {
          //     tenantId: user.tenantId,
          //     // "3ac1585b-156d-4c6e-8ffa-8b8440611f77"
          //   };
          //   await axios.post(`${backendUrl}/api/Tenant`, obj);
          // }

          setData(res.data);
        }
        respFirstRun = await axios.get(
          `${backendUrl}/api/Login/FirstrunFileExist`
        );
        console.log("respFirstRun:-", respFirstRun.data.fileExists);
        setFirstRun(respFirstRun.data.fileExists);
        setJsonDataFirstRun(respFirstRun.data.jsonData);
        setLoading(false);
      } catch (error) {
        console.error("Error", error);
        setSideBarError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchBackendConfig();

    if (localStorage.getItem("mode")) {
      setTheme(localStorage.getItem("mode"));
    } else {
      localStorage.setItem("mode", "Light Mode");
      setTheme("Light Mode");
    }
  }, [user]);

  return (
    <MyContext.Provider
      value={{
        user,
        setSignedInUser,
        resetUser,
        data,
        setData,
        displayData,
        setDisplayDataFunction,
        loading,
        setLoadingFunction,
        sidebarError,
        sideBarErrorFun,
        threadID,
        setThreadIDFunction,
        setThemeFunction,
        theme,
        backendUrl,
        setBackendUrlFunction,
        demoImage,
        setDemoImageFuntion,
        deploymentStatus,
        setDeploymentStatusFunction,
        firstRun,
        setFirstRunFunction,
        firstRunData,
        setFirstRunDataFunction,
        clientId,
        setClientIdFunction,
        jsonDataFirstRun,
        imageCall,
        setImageCall,
        activeLink,
        setActiveLink,
        setInternalError,
        internalError,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
