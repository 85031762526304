import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainDashboard from "./components/MainDashboard";
import Login from "./components/Login";
import "./App.css";
import RequireAuth from "./auth/RequireAuth";
import Support from "./components/Support";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import LandingPage from "./components/LandingPage";
import CLI from "./components/CLI";
import Signup from "./components/Signup";
import ErrorPage from "./components/ErrorPage";
import LoginError from "./components/LoginError";

// export const appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: "617d09b5-2cd0-4d6c-bac4-3449394d15fd",
//     enableAutoRouteTracking: true,
//     enableDebug: true,
//   },
// });

// appInsights.loadAppInsights();

const App = () => {
  // useEffect(() => {
  //   appInsights.trackPageView();
  // }, []);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<Signup />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/loginError" element={<LoginError />} />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <MainDashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/cli"
        element={
          // <RequireAuth>
          <CLI />
          // </RequireAuth>
        }
      />
      <Route path="/support" element={<Support />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default App;
