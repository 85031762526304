import React, { useContext } from "react";
import MyContext from "../auth/MyContext";

const ShowThreadId = () => {
  const { threadID } = useContext(MyContext);
  return (
    <div
      style={{
        paddingLeft: "30px",
        fontSize: "12px",
        color: "#edd5db",
      }}
    >
      <p style={{ margin: "0px" }}> {threadID}</p>
    </div>
  );
};

export default ShowThreadId;
