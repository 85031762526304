import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  InputGroup,
  Form,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { VscClose } from "react-icons/vsc";
import { FiSend } from "react-icons/fi";
import { FaPuzzlePiece } from "react-icons/fa";
import { AiTwotoneFileText } from "react-icons/ai";
import axiosURL from "../axios";
import axios from "axios";
import right from "../assets/right.jpg";
import OpenAI from "openai";
import { foreMessages } from "./Foreknowledge.jsx";
import { Link } from "react-router-dom";
import CLI from "./CLI.jsx";
import "./Dashboard.css";
import "../App.css";
import "highlight.js/styles/default.css";
import Capabilities from "./Capabilities.jsx";
import DeploymentStatusCard from "./DeploymentStatusCard.jsx";
import DeploymentCompletedCard from "./DeploymentCompletedCard.jsx";
import MyContext from "../auth/MyContext";
import ShowThreadId from "./ShowThreadId.jsx";
import DisplayDataComponent1 from "./DisplayDataComponent1";

const Dashboard1 = () => {
  const {
    user,
    data,
    setData,
    displayData,
    setDisplayDataFunction,
    threadID,
    setThreadIDFunction,
    theme,
    backendUrl,
    demoImage,
    deploymentStatus,
    setDeploymentStatusFunction,
    firstRun,
    imageCall,
    setImageCall,
    setActiveLink,
    internalError,
    setInternalError,
  } = useContext(MyContext);
  const scrollableRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [textData, settextData] = useState("");
  const [imagePath, setImagePath] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowNum, setRowNum] = useState(1);
  const maxRows = 5;
  const textAreaRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const textInputRef = useRef(null);
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }, [displayData]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
    if (textData === "") {
      setRowNum(1);
    }
  }, [textData]);

  useEffect(() => {
    const firstRunFuntion = async () => {
      if (firstRun) {
        try {
          if (backendUrl !== "") {
            const obj = [
              {
                Source: "Request",
                Content: "Hello",
              },
            ];
            // console.log("backendUrl:-", backendUrl);
            const resp = await axios.post(
              `${backendUrl}/api/Foreknowledge`,
              obj
            );
            //console.log("chatrespGroup:-", resp);
            setDisplayDataFunction([resp.data]);
          }
        } catch (error) {
          console.log("Error:-", error);
          setInternalError({
            status: true,
            message: error,
            code: error.code,
          });
        }
      }
    };
    //firstRunFuntion();
  }, [backendUrl]);

  const handleToggleEdit = (index) => {
    if (editingIndex === index) {
      setEditingIndex(null);
      setEditValue("");
    } else {
      setEditingIndex(index);
      setEditValue(displayData[index].content);
    }
  };

  const handleSave = async () => {
    try {
      let newDisplayData = [...displayData];
      newDisplayData[editingIndex].request = editValue;
      const formData = new FormData();
      formData.append("ThreadId", newDisplayData[editingIndex].threadId);
      formData.append("GroupId", newDisplayData[editingIndex].id);
      formData.append("Request", editValue);
      const chatrespGroup = await axios.post(
        `${backendUrl}/api/ChatMessage`,
        formData
      );
      newDisplayData[editingIndex].response = chatrespGroup.data.response;
      setDisplayDataFunction(newDisplayData);
      setEditingIndex(null);
      setEditValue("");
    } catch (error) {
      console.log("Error:-", error);
      setInternalError({
        status: true,
        message: error,
        code: error.code,
      });
    }
  };
  const handleCancel = () => {
    setEditingIndex(null);
    setEditValue("");
  };

  const removeImage = () => {
    setSelectedImage(null);
  };

  const callForeknowledge = async (obj) => {
    try {
      setLoading(true);
      const resp = await axios.post(`${backendUrl}/api/Foreknowledge`, [
        ...displayData,
        obj,
      ]);
      console.log("res Foreknowledge:-", resp);
      setDisplayDataFunction([...displayData, obj, resp.data]);
    } catch (error) {
      console.log("Error:-", error);
      setInternalError({
        status: true,
        message: error,
        code: error.code,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    if (event.key === "Enter" || event.type === "click") {
      try {
        event.preventDefault();
        if (!firstRun) {
          console.log("firstrun");
          const obj = {
            source: "Request",
            content: event.target.value,
          };
          setDisplayDataFunction([...displayData, obj]);
          settextData("");
          callForeknowledge(obj);
        } else {
          let tID = "",
            docResp = "",
            imgCall = false;

          if (selectedImage) {
            const imgFormData = new FormData();
            imgFormData.append("TenantId", user.tenantId);
            imgFormData.append("Source", "Request");
            imgFormData.append("File", file);

            docResp = await axios.post(
              `${backendUrl}/api/Document`,
              imgFormData
            );
            console.log("docResp:-", docResp);
            setSelectedImage(null);
            setImagePath(null);
            setFile(null);
            setImageCall(true);
            imgCall = true;
          }
          const chatMessagesSave = {
            source: "Request",
            content:
              event.target.value === "" ? "Image upload" : event.target.value,
            attempt: 1,
          };
          if (docResp !== "") {
            chatMessagesSave.document = docResp.data;
            chatMessagesSave.documentId = docResp.data.id;
            // chatMessagesSave.url = docResp.data.url;
          }
          setSelectedImage(null);
          setImagePath(null);
          setFile(null);
          settextData("");
          setDisplayDataFunction([...displayData, chatMessagesSave]);
          chatMessagePost(chatMessagesSave, imgCall);
        }
      } catch (error) {
        console.error("Error:-", error);
        setInternalError({ status: true, message: error, code: error.code });
      } finally {
      }
    }
  };
  // chatMessagesSave.hasOwnProperty("document") ? "image" : "default"
  const chatMessagePost = async (chatMessagesSave, imgCall) => {
    try {
      let tID = "",
        docResp = "";
      setLoading(true);
      if (displayData.length === 0) {
        const formData = new FormData();
        // user.tenantId;
        formData.append("TenantId", "4779C6AF-0689-4195-A99F-0E9C8B2C0652");
        formData.append("Owner", "7F03D6AA-356B-4F8D-ABA4-04082EBC0A0E");
        formData.append(
          "Title",
          chatMessagesSave.content === ""
            ? "Image upload"
            : chatMessagesSave.content
        );
        formData.append("IsGlobal", false);
        formData.append("Url", "www.www.com");
        formData.append("Status", "Open");

        const resThreadID = await axios.post(
          `${backendUrl}/api/ChatThread`,
          formData
        );
        console.log("resThreadID new:-", resThreadID);
        setThreadIDFunction(resThreadID.data.id);
        tID = resThreadID.data.id;
        setData([...data, resThreadID.data]);
        setActiveLink(resThreadID.data.id);
      }

      chatMessagesSave = {
        ...chatMessagesSave,
        threadId: threadID ? threadID : tID,
        groupId: threadID ? threadID : tID,
      };
      // console.log("chatMessagePost **:-", displayData);
      const chatMessagesSaveArray = [...displayData, chatMessagesSave];
      const chatMessageRes = await axios.post(
        `${backendUrl}/api/ChatMessages?sysMessage=${
          imgCall || imageCall ? "image" : "default"
        }`,
        chatMessagesSaveArray,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("chatMessageRes new:", chatMessageRes);
      setDisplayDataFunction([
        ...displayData,
        chatMessagesSave,
        chatMessageRes.data,
      ]);
    } catch (error) {
      console.error("Error ChatMessage:-", error);
      setInternalError({
        status: true,
        message: error,
        code: error.code,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChooseImage = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const iPath = URL.createObjectURL(file);
      setImagePath(iPath);
      setSelectedImage(file.name);
      setFile(file);

      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    }
  };

  return (
    <>
      {/* {firstRun && callFunctionImageDemo(...foreMessages)} */}
      <div className="div-dashboard">
        <div
          className="App"
          style={{
            height: "100vh",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            marginLeft: "30px",
            marginRight: "30px",
          }}
        >
          {displayData.length === 0 ? (
            <>
              {" "}
              <div className="div-center">
                <Capabilities />
              </div>
            </>
          ) : (
            <div className="data-style align-items-center" ref={scrollableRef}>
              <Row className="align-items-center">
                <Col lg="12">
                  <DisplayDataComponent1
                    displayData={displayData}
                    editingIndex={editingIndex}
                    theme={theme}
                    demoImage={demoImage}
                    handleToggleEdit={handleToggleEdit}
                    loading={loading}
                    setEditValue={setEditValue}
                    editValue={editValue}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    imageCall={imageCall}
                  />
                </Col>
              </Row>
              {deploymentStatus && <DeploymentStatusCard />}
              {showDiv && <DeploymentCompletedCard />}
            </div>
          )}
          <div className="overlay-style">
            <Row className="align-items-end " style={{ margin: "0px" }}>
              <Card
                className={`${
                  theme === "Dark Mode" ? "dark-theme-style " : ""
                }card-height`}
              >
                {selectedImage && (
                  <Col lg="12">
                    <div className="text-image-css">
                      <div className="text-image-position">
                        <AiTwotoneFileText />
                        &nbsp;
                        <span>{selectedImage}</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Remove</Tooltip>}
                        >
                          <Button
                            variant="outlined"
                            onClick={removeImage}
                            className="close-btn-style"
                          >
                            <VscClose />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Col>
                )}
                <Col>
                  <div className="text-box-style">
                    <InputGroup
                      className={`"form-control-border"
                            ${theme} === "Dark Mode" ? "dark-theme-style" : ""  `}
                    >
                      <Form.Control
                        placeholder={`Your wish’s my command... what do you desire?`}
                        aria-label={`Your wish’s my command... what do you desire?`}
                        id="type_msg"
                        as="textarea"
                        type="text"
                        rows={rowNum > maxRows ? maxRows : rowNum}
                        value={textData}
                        ref={textInputRef}
                        onChange={(e) => {
                          settextData(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey) {
                            e.preventDefault();
                            settextData(textData + "\n");
                            setRowNum((prevRowNum) => prevRowNum + 1);
                          } else {
                            handleSubmit(e);
                          }
                        }}
                        style={{
                          border: "none",
                          resize: "none",
                          outline: "none",
                          overflowY: "auto",
                          maxHeight: `${maxRows * 1.2}em`,
                          padding: 0,
                        }}
                        className={
                          theme === "Dark Mode"
                            ? "dark-text-box no-focus-border-dark"
                            : "no-focus-border"
                        }
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">File Upload</Tooltip>
                        }
                      >
                        <InputGroup.Text
                          className={` "icons-color" ${theme} === "Dark Mode" ? "dark-theme-style" : ""`}
                          onClick={handleChooseImage}
                          style={
                            theme === "Dark Mode"
                              ? { backgroundColor: "#565869" }
                              : {}
                          }
                        >
                          <AiTwotoneFileText style={{ padding: "0" }} />
                        </InputGroup.Text>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Send</Tooltip>}
                      >
                        <InputGroup.Text
                          className={`icons-color  ${
                            !textData.trim() && !selectedImage ? "disabled" : ""
                          }
                              
                             ${theme} === "Dark Mode" ? "dark-theme-style" : ""`}
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          style={
                            theme === "Dark Mode"
                              ? { backgroundColor: "#565869" }
                              : {}
                          }
                        >
                          <FiSend />
                        </InputGroup.Text>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">App</Tooltip>}
                      >
                        <InputGroup.Text
                          className={` "icons-color"`}
                          style={
                            theme === "Dark Mode"
                              ? { backgroundColor: "#565869" }
                              : {}
                          }
                        >
                          <FaPuzzlePiece />
                        </InputGroup.Text>
                      </OverlayTrigger>
                      <input
                        type="file"
                        accept="image/*,.doc,.docx,.pdf,.txt"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleImageChange}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Card>
            </Row>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // border: "red solid 3px",
          marginTop: "5px",
        }}
      >
        {threadID !== null && <ShowThreadId />}
        <div style={{ paddingRight: "30px", marginLeft: "auto" }}>
          <Link to="/cli" target="_blank" rel="noopener noreferrer">
            Download Genie
          </Link>
        </div>
      </div>
    </>
  );
};

export default Dashboard1;
