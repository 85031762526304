import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  InputGroup,
  Form,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { IoFlashOutline } from "react-icons/io5";
import CardData from "./CardData";
import InfraGenie_ai1 from "../assets/InfraGenie_aiSmall.svg";
import "../App.css";
import { VscClose } from "react-icons/vsc";
import { FiSend } from "react-icons/fi";
import { FaPuzzlePiece } from "react-icons/fa";
import { AiTwotoneFileText } from "react-icons/ai";
import MyContext from "../auth/MyContext";
import "./Dashboard.css";
import axiosURL from "../axios";
import { ThreeDots } from "react-loader-spinner";
import "highlight.js/styles/default.css";
import DisplayDataComponent from "./DisplayDataComponent";
import axios from "axios";
import right from "../assets/right.jpg";
import OpenAI from "openai";
import { foreMessages } from "./Foreknowledge.jsx";
import { Link } from "react-router-dom";
import CLI from "./CLI.jsx";
import { disable } from "workbox-navigation-preload";

const Dashboard = () => {
  // window.location.reload();
  const {
    user,
    data,
    setData,
    displayData,
    setDisplayDataFunction,
    threadID,
    setThreadIDFunction,
    theme,
    backendUrl,
    demoImage,
    deploymentStatus,
    setDeploymentStatusFunction,
    firstRun,
    imageCall,
    setImageCall,
  } = useContext(MyContext);

  const scrollableRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [textData, settextData] = useState("");
  const [imagePath, setImagePath] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowNum, setRowNum] = useState(1);
  const maxRows = 5;
  const textAreaRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const textInputRef = useRef(null);
  const [showDiv, setShowDiv] = useState(false);
  const [visionMessage, setVisionMessage] = useState([
    {
      role: "system",
      content: `AI, your role is to assist the user in identifying Azure services present in an image and gather information about those services. Here's the step-by-step process:

            1. Detect and list the Azure services found in the image and say "Here is the list of azure serives, do you want to continue?".
            2. If user say "yes" then for each Azure service identified in list excludeing 'Azure App Service Plan,', 'Resource Group', 'Azure SQL Servers' ask below questions one by one:
                - What is the project name?
                  (when user enters the project name convert this project name in 7 letter short form of the description and it should be small case only)
                - Please provide the description of the project                
                - If the service is 'Azure Web App,' and  there are multiple 'Azure Web App' instances, then ask below A & B Questions one by one like "What is the purpose of the first web app? etc.
                    A. What is the purpose of the web app?
                    B. What is the tech stack used for the web app?               
                - If the service is 'Azure SQL Database, and  there are multiple 'Azure SQL Database' instances ' then ask then ask below A  Question "Which type of database do yuo want for first database instances? etc:
                    A. Which type of database do you want?
                - For remaining each services ask:
                    A. What is the purpose of the service name?
                  
            3. Store the user's responses in a JSON format given below, including the project name and description

               
                Example JSON format:
                {
                "projectName": "Project Name",
                "description": "Project Description",
                "services": [
                {
                "type": "Azure Web App",
                "purpose": "Web App Purpose",
                "techStack": "Tech Stack,
                },
                {
                "type": "Azure SQL Database",
                "databaseType": "Database Type"
                },
                {
                "type": "Other service name",
                "purpose": "purpose",
                },
                etc.
                ]
                }

              After you have completed asking questions to the user just say "Thank you for providing the information. This is the ThreadId:-${threadID} for your instance, conveniently accessible on the Azure Portal for the deployment."

                Please follow this process to gather information about Azure services from the user and store it in the specified JSON format.
`,
    },
    {
      role: "user",
      content: [
        // {
        //   type: "text",
        //   text: `which icon does the image represent in the Azure?`,
        //   // text: `Provide the Dependecny detection of the services in the image.
        //   //     Note: Don't provide any additional text only provide the Dependecny`,
        // },
        {
          type: "image_url",
          image_url: "",
        },
      ],
    },
  ]);

  const handleToggleEdit = (index) => {
    if (editingIndex === index) {
      setEditingIndex(null);
      setEditValue("");
    } else {
      setEditingIndex(index);
      setEditValue(displayData[index].request);
    }
  };

  const handleSave = async () => {
    try {
      let newDisplayData = [...displayData];
      newDisplayData[editingIndex].request = editValue;
      const formData = new FormData();
      formData.append("ThreadId", newDisplayData[editingIndex].threadId);
      formData.append("GroupId", newDisplayData[editingIndex].id);
      formData.append("Request", editValue);
      const chatrespGroup = await axios.post(
        `${backendUrl}/api/ChatMessage`,
        formData
      );
      newDisplayData[editingIndex].response = chatrespGroup.data.response;
      setDisplayDataFunction(newDisplayData);
      setEditingIndex(null);
      setEditValue("");
    } catch (error) {
      console.log("Error:-", error);
    }
  };

  const handleCancel = () => {
    setEditingIndex(null);
    setEditValue("");
  };

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }, [displayData]);

  useEffect(() => {
    if (!deploymentStatus) return;

    const POLLING_INTERVAL = 5000; // Poll every 5 seconds. Adjust as needed.
    const fetchDeploymentStatus = async () => {
      try {
        let response = await axios.get(
          `${backendUrl}/api/getDeploymentStatus/rg-agntprt-dev-eastus-003`
        );
        // let data = await response.json();

        let currentStatus = response.data.status.value;

        switch (currentStatus) {
          case "Running":
            break;
          case "Succeeded":
            clearInterval(pollingInterval); // Stop polling
            setDeploymentStatusFunction(false);
            setShowDiv(true);
            break;
          case "Failed":
            clearInterval(pollingInterval); // Stop polling
            // Handle the failed status if needed
            break;
          default:
            console.log("Unknown deployment status:", data.status);
            clearInterval(pollingInterval); // Stop polling
        }
      } catch (error) {
        console.error("Failed fetching deployment status:", error);
        clearInterval(pollingInterval); // Stop polling in case of an error
      }
    };

    // Start polling
    const pollingInterval = setInterval(
      fetchDeploymentStatus,
      POLLING_INTERVAL
    );

    return () => {
      clearInterval(pollingInterval); // Cleanup the interval when the component unmounts or deploymentStatus changes
    };
  }, [deploymentStatus]);

  async function callChatMessage(obj, vCall, vMsg) {
    // console.log("vMsg in callChatMessage start :-", vMsg);
    try {
      const chatThread = {
        name: textData === "" ? "Image?" : textData,
        uniqueId: user.uniqueId,
        tenantId: user.tenantId,
      };
      let tID;
      let resPostChat;
      setLoading(true);
      if (displayData.length === 0) {
        resPostChat = await axios.post(
          `${backendUrl}/api/ChatThread`,
          chatThread
        );
        setThreadIDFunction(resPostChat.data.id);
        tID = resPostChat.data.id;
        setData([...data, resPostChat.data]);
        setLoading(false);
      } else {
        tID = threadID;
      }
      const formData = new FormData();
      formData.append("ThreadId", tID);
      formData.append("GroupId", tID);
      formData.append("Request", textData === "" ? "Image" : textData);

      let chatresp;
      if (obj.flag === 1) {
        const formData1 = new FormData();
        // formData.append("file", file);
        formData1.append("file", file);
        formData1.append("LinkType", "azure");
        formData1.append("LinkedId", "349D1CB7-1EE6-4769-88FC-0EBB809DBBC2");
        chatresp = await axios.post(`${backendUrl}/api/Document`, formData1);
        console.log("chatreso document:-", chatresp);
      }
      // chatresp = await axios.post(`${backendUrl}/api/ChatMessage`, formData);

      let visionRes;
      if (vCall || imageCall) {
        const lastMessageContent = vMsg[vMsg.length - 1].content;
        if (
          Array.isArray(lastMessageContent) &&
          lastMessageContent.some((content) => "image_url" in content)
        ) {
          vMsg[vMsg.length - 1].content.forEach((content) => {
            if ("image_url" in content) {
              content.image_url = chatresp.data.location; // Updating the image_url
            }
          });
        }
        visionRes = await callVisionAPI(vMsg);
        let abc = [
          ...vMsg,
          {
            role: "assistant",
            content: visionRes,
          },
        ];
        setVisionMessage(abc);
        // formData.append("Response", visionRes);
        // formData.append("")
        // chatresp = await axios.post(`${backendUrl}/api/Post`, formData);
      } else {
        chatresp = await axios.post(`${backendUrl}/api/ChatMessage`, formData);
      }
      setLoading(false);
      let demooo;
      // chatresp.data.hasOwnProperty("documentId");
      console.log("chatresp.data.:-", chatresp.data);
      if (chatresp.data.hasOwnProperty("location")) {
        demooo = {
          ...chatresp.data,
          flag: 1,
          documentId: chatresp.data.documentId,
          location: chatresp.data.location,
          response: visionRes,
        };
      } else {
        demooo = {
          ...chatresp.data,
          flag: 0,
        };
      }
      if (vCall || imageCall) {
        demooo = {
          ...demooo,
          response: visionRes,
        };
      }

      setLoading(false);

      const oo = [...displayData, demooo];

      setFile(null);
      setSelectedImage(null);
      setDisplayDataFunction(oo);
    } catch (error) {
      let demo = {
        request: textData,
        img: "",
        flag: 2,
      };
      if (error.response) {
        console.log("***error.response");
        console.log("error.response.data:-", error.response.data);
        console.log("error.response.status:-", error.response.status);
        console.log("error.response.headers:-", error.response.headers);
        demo = {
          ...demo,
          response: `${error.response.status} An error occured`,
        };
      } else if (error.request) {
        console.log(error.request);
        demo = {
          ...demo,
          response: `${error.request} An error occured`,
        };
      } else {
        console.error("Error", error);
        demo = {
          ...demo,
          response: `${error.message} An error occured`,
        };
      }
      const oo = [...displayData, demo];
      setDisplayDataFunction(oo);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (event) => {
    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();
      let vCall = false;
      let vMsg = [];
      setRowNum(1);
      let obj = {
        request: textData === "" ? "Image" : textData,
        response: "",
      };
      if (selectedImage) {
        console.log("imagePath:-", imagePath);
        obj = {
          ...obj,
          img: imagePath,
          flag: 1,
          file: file,
        };
        setImageCall(true);
        vCall = true;
      } else {
        obj = {
          ...obj,
          img: "",
          flag: 0,
        };
      }

      if (imageCall) {
        if (selectedImage) {
          vMsg = [
            ...visionMessage,
            {
              role: "user",
              content: [
                {
                  type: "text",
                  text: textData,
                },
                {
                  type: "image_url",
                  image_url: "",
                },
              ],
            },
          ];
        } else {
          vMsg = [
            ...visionMessage,
            {
              role: "user",
              content: textData,
            },
          ];
        }
        console.log("vMsg in submit:- ***", vMsg);
        setVisionMessage(vMsg);
      }
      // else if (selectedImage) {
      //     vMsg = [
      //       ...visionMessage,
      //       {
      //         role: "user",
      //         content: textData,
      //       },

      //     ];
      // }
      else {
        vMsg = [...visionMessage];
      }
      callChatMessage(obj, vCall, vMsg);

      const oo = [...displayData, obj];
      setDisplayDataFunction(oo);
      settextData("");
      setSelectedImage(null);
      setImagePath(null);
      setFile(null);
    }
  };

  const handleChooseImage = () => {
    fileInputRef.current.click();
  };

  // useEffect(() => {
  //   const dmsg=
  //   callVisionAPI();
  // }, []);

  const callVisionAPI = async (objArray) => {
    const openai = new OpenAI({
      apiKey: "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",
      dangerouslyAllowBrowser: true,
    });
    // console.log("objArray:-", objArray);
    const response = await openai.chat.completions.create({
      model: "gpt-4-vision-preview",
      messages: objArray,
      temperature: 1,
      max_tokens: 300,
    });
    // const obj = {
    //   img: "",
    //   flag: 0,
    //   response: response.choices[0].message.content,
    // };
    // console.log("Image Reading:-", response.choices[0].message.content);
    return response.choices[0].message.content;
    // const oo = [...displayData, obj];

    // setDisplayDataFunction(oo);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const iPath = URL.createObjectURL(file);
      setImagePath(iPath);
      setSelectedImage(file.name);
      setFile(file);

      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    }
  };

  const removeImage = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
    if (textData === "") {
      setRowNum(1);
    }
  }, [textData]);

  // useEffect(() => {
  //   const getFirstRunCall = async () => {
  //     const res = await callFunctionImageDemo([...foreMessages]);
  //     console.log("res:-", res);
  //   };
  //   getFirstRunCall();
  // }, []);

  return (
    <>
      {/* {firstRun && callFunctionImageDemo(...foreMessages)} */}
      <div className="div-dashboard">
        <div
          className="App"
          style={{
            height: "100vh",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            marginLeft: "30px",
            marginRight: "30px",
          }}
        >
          {displayData.length === 0 ? (
            <>
              {" "}
              <div className="div-center">
                <Row>
                  <Col lg="12">
                    <img src={InfraGenie_ai1} alt="InfraGenie" height="150px" />

                    <h5
                      style={{
                        color: theme === "Dark Mode" ? "white" : "black",
                      }}
                    >
                      <IoFlashOutline />
                      Capabilities
                    </h5>
                  </Col>
                </Row>

                <Row className="justify-content-md-center">
                  <CardData data="Create a brand new version of Azure Foundation using Hub / Spoke model" />
                  <CardData data="Create infrastructure for specific projects by the Architecture diagram uploaded" />
                  <CardData data="Update or modify the infrastructure using a Chat based interface" />
                  <CardData data="DevOps teams can collaborate with Genie by having the generated code very much human readable - checked-in and DevOps pipelines created" />
                  <CardData data="Use as a private chat interface of your own private instance of ChatGPT" />
                </Row>
              </div>
            </>
          ) : (
            <div className="data-style align-items-center" ref={scrollableRef}>
              <Row className="align-items-center">
                <Col lg="12">
                  <DisplayDataComponent
                    displayData={displayData}
                    editingIndex={editingIndex}
                    theme={theme}
                    demoImage={demoImage}
                    handleToggleEdit={handleToggleEdit}
                    loading={loading}
                    setEditValue={setEditValue}
                    editValue={editValue}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    imageCall={imageCall}
                  />
                </Col>
              </Row>
              {deploymentStatus && (
                <Row>
                  <Col
                    lg="3"
                    style={{
                      background: theme === "Dark Mode" ? "#565869" : "#f7f7f7",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      marginLeft: "15px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="5"
                        color="grey"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ marginRight: "10px" }}
                        wrapperClassName=""
                        visible={true}
                      />
                      <span
                        style={{
                          color: theme === "Dark Mode" ? "white" : "",
                        }}
                      >
                        Deploying ...
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        alignSelf: "stretch",
                        marginTop: "-10px",
                      }}
                    >
                      <a
                        href="https://portal.azure.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Azure Portal
                      </a>
                      <a
                        href="#cancel-action"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeploymentStatusFunction(false);
                        }}
                      >
                        Cancel
                      </a>
                    </div>
                  </Col>
                </Row>
              )}
              {showDiv && (
                <Row>
                  <Col
                    lg="3"
                    style={{
                      background: "#f7f7f7",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      marginLeft: "15px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={right}
                        alt="right img"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      />
                      &nbsp;
                      <span> Deployment Complete</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        alignSelf: "stretch",
                        marginTop: "-10px",
                      }}
                    >
                      <a
                        href="https://portal.azure.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Azure Portal
                      </a>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          )}
          <div className="overlay-style" style={{ marginBottom: "50px" }}>
            <Row className="align-items-end " style={{ margin: "0px" }}>
              <Card className={theme === "Dark Mode" ? "dark-theme-style" : ""}>
                {selectedImage && (
                  <Col lg="12">
                    <div className="text-image-css">
                      <div className="text-image-position">
                        <AiTwotoneFileText />
                        &nbsp;
                        <span>{selectedImage}</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Remove</Tooltip>}
                        >
                          <Button
                            variant="outlined"
                            onClick={removeImage}
                            className="close-btn-style"
                          >
                            <VscClose />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Col>
                )}
                <Col>
                  <div className="text-box-style">
                    <InputGroup
                      className={`"form-control-border"
                            ${theme} === "Dark Mode" ? "dark-theme-style" : ""  `}
                    >
                      <Form.Control
                        placeholder={`Your wish’s my command... what do you desire?`}
                        aria-label={`Your wish’s my command... what do you desire?`}
                        id="type_msg"
                        as="textarea"
                        type="text"
                        rows={rowNum > maxRows ? maxRows : rowNum}
                        value={textData}
                        ref={textInputRef}
                        onChange={(e) => {
                          settextData(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey) {
                            e.preventDefault();
                            settextData(textData + "\n");
                            setRowNum((prevRowNum) => prevRowNum + 1);
                          } else {
                            handleSubmit(e);
                          }
                          // disable = { loading };
                        }}
                        style={{
                          border: "none",
                          resize: "none",
                          outline: "none",
                          overflowY: "auto",
                          maxHeight: `${maxRows * 1.2}em`,
                        }}
                        className={
                          theme === "Dark Mode"
                            ? "dark-text-box no-focus-border-dark"
                            : "no-focus-border"
                        }
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">File Upload</Tooltip>
                        }
                      >
                        <InputGroup.Text
                          className={` "icons-color" ${theme} === "Dark Mode" ? "dark-theme-style" : ""`}
                          onClick={handleChooseImage}
                          style={
                            theme === "Dark Mode"
                              ? { backgroundColor: "#565869" }
                              : {}
                          }
                        >
                          <AiTwotoneFileText style={{ padding: "0" }} />
                        </InputGroup.Text>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Send</Tooltip>}
                      >
                        <InputGroup.Text
                          className={`icons-color  ${
                            !textData.trim() && !selectedImage ? "disabled" : ""
                          }
                              
                             ${theme} === "Dark Mode" ? "dark-theme-style" : ""`}
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          style={
                            theme === "Dark Mode"
                              ? { backgroundColor: "#565869" }
                              : {}
                          }
                        >
                          <FiSend />
                        </InputGroup.Text>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">App</Tooltip>}
                      >
                        <InputGroup.Text
                          className={` "icons-color"`}
                          style={
                            theme === "Dark Mode"
                              ? { backgroundColor: "#565869" }
                              : {}
                          }
                        >
                          <FaPuzzlePiece />
                        </InputGroup.Text>
                      </OverlayTrigger>
                      <input
                        type="file"
                        accept="image/*,.doc,.docx,.pdf,.txt"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleImageChange}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Card>
            </Row>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // border: "red solid 3px",
          marginTop: "5px",
        }}
      >
        {threadID !== null && (
          <div
            style={{
              paddingLeft: "30px",
              fontSize: "12px",
              color: "#edd5db",
            }}
          >
            <p style={{ margin: "0px" }}> {threadID}</p>
          </div>
        )}
        <div style={{ paddingRight: "30px", marginLeft: "auto" }}>
          <Link to="/cli" target="_blank" rel="noopener noreferrer">
            Download Genie
          </Link>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
