import React from "react";

// Initialize a Context with default values
const MyContext = React.createContext({
  user: null,
  data: null,
  setData: () => {},
});

export default MyContext;
