import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./CardData.css";
import MyContext from "../auth/MyContext";

const CardData = ({ data }) => {
  const { theme } = useContext(MyContext);
  return (
    <Row>
      <Col lg={{ span: 6, offset: 3 }}>
        <Card
          className={`no-border ${
            theme === "Dark Mode" ? "dark-card-border" : ""
          }`}
        >
          <Card.Body
            className={theme === "Dark Mode" ? "dark-card" : "light-card"}
          >
            <h6 className={theme === "Dark Mode" ? "h6-dark" : ""}>{data}</h6>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CardData;
