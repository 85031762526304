import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import MyContext from "../auth/MyContext";

const DeploymentStatusCard = () => {
  const { theme, setDeploymentStatusFunction } = useContext(MyContext);
  return (
    <Row>
      <Col
        lg="3"
        style={{
          background: theme === "Dark Mode" ? "#565869" : "#f7f7f7",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          marginLeft: "15px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ThreeDots
            height="50"
            width="50"
            radius="5"
            color="grey"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ marginRight: "10px" }}
            wrapperClassName=""
            visible={true}
          />
          <span
            style={{
              color: theme === "Dark Mode" ? "white" : "",
            }}
          >
            Deploying ...
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            alignSelf: "stretch",
            marginTop: "-10px",
          }}
        >
          <a
            href="https://portal.azure.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Azure Portal
          </a>
          <a
            href="#cancel-action"
            onClick={(e) => {
              e.preventDefault();
              setDeploymentStatusFunction(false);
            }}
          >
            Cancel
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default DeploymentStatusCard;
