export const config = {
  appId: "bc3703b6-e592-4140-aaaf-d166236ed64c",
  scopes: ["User.Read"],
  authority: "https://login.microsoftonline.com/common",
};
//df897cac-d267-47f8-b2a4-5a894d618d0b
// 3ac1585b-156d-4c6e-8ffa-8b8440611f7d

// Uncaught (in promise) BrowserAuthError: interaction_in_progress: Interaction is currently in progress. Please ensure that this interaction has been completed before calling an interactive API.  For more visit: aka.ms/msaljs/browser-errors.
//     at BrowserAuthError.AuthError [as constructor] (AuthError.ts:49:1)
