import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import InfraGenie_ai from "../assets/InfraGenie_aiSmall.svg";

const LoginError = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f0f4fa",
      }}
    >
      <Row>
        <Col>
          <img src={InfraGenie_ai} alt="InfraGenie" height="100px" />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>We ran into an issue while signing you in, please try again.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Go Back
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LoginError;
