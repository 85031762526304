import React from "react";
import privacypolicy from "../assets/privacy.png";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${privacypolicy})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  );
};

export default PrivacyPolicy;
