import React, { useContext } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LiaEdit } from "react-icons/lia";
import userlogo from "../assets/user.png";
import genieF from "../assets/GenieF.svg";
import MyContext from "../auth/MyContext";
import { ThreeDots } from "react-loader-spinner";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import hljs from "highlight.js";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { data } from "jquery";

const ImageOrDocument = ({ imgPath }) => {
  const fileExtension = imgPath.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
    const img = new Image();
    const imgWidth = 500; // Set your desired fixed width
    const imgHeight = 500; // Set your desired fixed height
    let originalWidth, originalHeight;
    const handleLoad = (event) => {
      const img = event.target;
      originalWidth = img.width;
      originalHeight = img.height;
      console.log("originalWidth:", originalWidth);
      console.log("originalHeight:", originalHeight);
    };

    console.log("width:-", originalWidth <= imgWidth ? "auto" : imgWidth);
    console.log("height:-", originalHeight <= imgHeight ? "auto" : imgHeight);
    return (
      <img
        src={imgPath}
        alt="userimage"
        // style={{ width: "100%", height: "auto" }}
        style={{
          width: originalWidth <= imgWidth ? "auto" : imgWidth,
          height: originalHeight <= imgHeight ? "auto" : imgHeight,
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        onLoad={handleLoad}
      />
    );
  }
  if (["doc", "docx", "pdf", "txt"].includes(fileExtension)) {
    return (
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          imgPath
        )}&embedded=true`}
        style={{ width: "100%", height: "500px" }}
        title="Doc preview"
      ></iframe>
    );
  }
  return null;
};

const EditableArea = ({
  editValue,
  setEditValue,
  handleSave,
  handleCancel,
}) => (
  <div>
    <textarea
      value={editValue}
      onChange={(e) => setEditValue(e.target.value)}
      style={{ width: "100%", border: "none" }}
    />
    <div>
      <Button onClick={handleSave} className="button-style" variant="success">
        Save & Submit
      </Button>
      <Button
        onClick={handleCancel}
        className="button-style"
        variant="outline-dark"
      >
        Cancel
      </Button>
    </div>
  </div>
);

export default function DisplayDataComponent1({
  displayData,
  editingIndex,
  editValue,
  setEditValue,
  handleSave,
  handleCancel,
  theme,
  demoImage,
  handleToggleEdit,
  loading,
  imageCall,
}) {
  const { backendUrl, threadID, setDeploymentStatusFunction, internalError } =
    useContext(MyContext);

  function determineLanguage(segment) {
    let language;
    try {
      language = hljs.highlightAuto(segment).language;
    } catch (e) {
      language = ""; // default to no specific language if detection fails
    }
    return language || "javascript";
  }

  return (
    <React.Fragment key={`displayData-fragment`}>
      {displayData.map((data, index) => {
        let seg = "";
        return (
          <React.Fragment key={index}>
            <Row
              key={`${index}-request`}
              className={`card-width-style ${
                data.source.includes("Response")
                  ? theme === "Dark Mode"
                    ? "card-background-color-infra-dark"
                    : "card-background-color-infra"
                  : ""
              } `}
            >
              <Col xs="auto">
                <img
                  src={data.source.includes("Request") ? userlogo : genieF}
                  alt={data.source.includes("Request") ? "User" : "Genie"}
                  style={{ width: "25px", height: "25px" }}
                />
              </Col>
              <Col>
                {/* {data.source.includes("Request") ? (
                  index === editingIndex ? (
                    <EditableArea
                      editValue={editValue}
                      setEditValue={setEditValue}
                      handleSave={handleSave}
                      handleCancel={handleCancel}
                    />
                  ) : (
                    <p
                      className={`text-css ${
                        theme === "Dark Mode" ? "dark-theme-color" : "black"
                      }`}
                    >
                      {data.content}
                    </p>
                  )
                ) : ( */}
                {data.content.split("```").map((segment, index) => {
                  if (index % 2 === 0) {
                    // It's regular text
                    return segment.split("\n").map((line, lineIndex) => {
                      if (line.trim() === "") return null; // Avoid rendering empty lines

                      const lineStyle =
                        data.source === "Request" && theme === "Dark Mode"
                          ? { color: "white" }
                          : {};

                      return (
                        <div key={`${index}-${lineIndex}`} style={lineStyle}>
                          <ReactMarkdown>{line}</ReactMarkdown>
                        </div>
                      );
                    });
                  } else {
                    seg = seg + segment;
                  }
                })}
                {/* )} */}
                {seg && (
                  <div
                    style={{
                      margin: "10px 0",
                      padding: "10px",
                      backgroundColor: "black",
                    }}
                  >
                    <SyntaxHighlighter
                      language={determineLanguage(seg)}
                      style={atomOneDark}
                      customStyle={{ backgroundColor: "black" }}
                    >
                      {seg}
                    </SyntaxHighlighter>
                  </div>
                )}

                {data.hasOwnProperty("document") ? (
                  <ImageOrDocument imgPath={data.document.url} />
                ) : null}
              </Col>
              {data.source.includes("Request") ? (
                <Col
                  xs="auto"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleToggleEdit(index)}
                >
                  <LiaEdit
                    style={{ color: theme === "Light Mode" ? "" : "white" }}
                  />
                </Col>
              ) : null}
            </Row>
          </React.Fragment>
        );
      })}
      {(loading || internalError.status) && (
        <Row
          key={`request`}
          className={`card-width-style ${
            theme === "Dark Mode"
              ? "card-background-color-infra-dark"
              : "card-background-color-infra"
          } `}
        >
          <Col xs="auto">
            <img
              src={genieF}
              alt={"Genie"}
              style={{ width: "25px", height: "25px" }}
            />
          </Col>
          <Col>
            <div
              className={`text-css ${
                theme === "Dark Mode" ? "dark-theme-color" : "black"
              }`}
            >
              {internalError.status ? (
                <div
                  style={{
                    border: internalError.status ? "red solid 2px" : "none",
                    backgroundColor: internalError.status ? "#edd5d5" : "none",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ color: "red", padding: "5px" }}>
                    {internalError.code === "ERR_NETWORK"
                      ? `Network Error`
                      : `Something went wrong. If this issue persists contact us through our help center ${internalError.code}`}
                  </p>
                </div>
              ) : (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="grey"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
