import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import InfraGenie_ai1 from "../assets/InfraGenie_aiSmall.svg";
import { IoFlashOutline } from "react-icons/io5";
import CardData from "./CardData";
import MyContext from "../auth/MyContext";

const Capabilities = () => {
  const { theme } = useContext(MyContext);
  return (
    <>
      <Row>
        <Col lg="12">
          <img src={InfraGenie_ai1} alt="InfraGenie" height="150px" />

          <h5
            style={{
              color: theme === "Dark Mode" ? "white" : "black",
            }}
          >
            <IoFlashOutline />
            Capabilities
          </h5>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <CardData data="Create a brand new version of Azure Foundation using Hub / Spoke model" />
        <CardData data="Create infrastructure for specific projects by the Architecture diagram uploaded" />
        <CardData data="Update or modify the infrastructure using a Chat based interface" />
        <CardData data="DevOps teams can collaborate with Genie by having the generated code very much human readable - checked-in and DevOps pipelines created" />
        <CardData data="Use as a private chat interface of your own private instance of ChatGPT" />
      </Row>
    </>
  );
};

export default Capabilities;
