import React, { useEffect } from "react";

const CLI = () => {
  useEffect(() => {
    // Trigger the download of the Get-Genie.ps1 file
    const downloadLink = document.createElement("a");
    downloadLink.href =
      "https://stshareddev002.blob.core.windows.net/common/Get-Genie.ps1?sp=r&st=2023-11-27T04:48:46Z&se=2025-01-01T12:48:46Z&spr=https&sv=2022-11-02&sr=b&sig=XsTCrmHY88ePGl7wl8hjUoTzjHL62vtZ80Veun7WIzE%3D";
    downloadLink.download = "Get-Genie.ps1";
    downloadLink.click();
  }, []);
  return <div></div>;
};

export default CLI;
