import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import MyContext from "./MyContext";

export const useAuthContext = () => {
  return React.useContext(MyContext);
};

export default function RequireAuth({ children }) {
  const auth = useAuthContext();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
