import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Navbar,
  Nav,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { RxChatBubble } from "react-icons/rx";
import { AiOutlineEdit } from "react-icons/ai";
import { FiShare } from "react-icons/fi";
import "./Sidebar.css";
import Genie from "../assets/GeniewithoutBG.png";
import MyContext from "../auth/MyContext";
import axiosURL from "../axios";
import axios, { formToJSON } from "axios";
import Loader from "./Loader";
import InfraGenie_ai from "../assets/InfraGenie_aiSmall.svg";
import rhipheus_logo from "../assets/rhipheus.svg";
import { BiCheck } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "../config";
import { AiOutlineGlobal } from "react-icons/ai";

function MyVerticallyCenteredModal(props) {
  const { theme, setThemeFunction } = useContext(MyContext);
  const [mode, setMode] = useState(theme);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="responsive-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Theme</h5>
        <DropdownButton
          id="username"
          variant="secondary"
          title={mode}
          className="mt-2 custom-dropdown"
          data-bs-theme="dark"
          drop="down"
        >
          <Dropdown.Item
            className="custom-dropdown-item"
            onClick={() => {
              // localStorage.setItem("mode", "Dark Mode");
              setMode("Dark Mode");
              setThemeFunction("Dark Mode");
            }}
          >
            Dark Mode
          </Dropdown.Item>
          <Dropdown.Item
            className="custom-dropdown-item"
            onClick={() => {
              // localStorage.setItem("mode", "Light Mode");
              setMode("Light Mode");
              setThemeFunction("Light Mode");
            }}
          >
            Light Mode
          </Dropdown.Item>
        </DropdownButton>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="dark"
          onClick={() => {
            setThemeFunction(mode);
            props.onHide();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Sidebar = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const {
    user,
    data,
    setDisplayDataFunction,
    loading,
    sidebarError,
    setLoadingFunction,
    sideBarErrorFun,
    threadID,
    setData,
    setThreadIDFunction,
    backendUrl,
    demoImage,
    setDemoImageFuntion,
    clientId,
    imageCall,
    setImageCall,
    activeLink,
    setActiveLink,
  } = useContext(MyContext);
  // const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const [data, setData] = useState(sideBarData);
  const [today, setToday] = useState([]);
  const [lastDays, setLastDays] = useState([]);
  //const [activeLink, setActiveLink] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState("");
  const [saveClicked, setSaveClicked] = useState(false);
  const [successfulRequests, setSuccessfulRequests] = useState({});
  const [clickedId, setClickedId] = useState(null);
  const sidebarRef = useRef(null);

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: clientId,
      redirectUri: window.location.href.includes("localhost")
        ? "https://localhost:44482/login"
        : "https://infragenie.ai/login",
      authority: config.authority,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  });

  const getData = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getLocalDate = (dateStr) => {
      let date = new Date(dateStr);
      date.setHours(0, 0, 0, 0);
      return date;
    };

    if (data !== null) {
      let todaysDates = data
        .filter(
          (item) => getLocalDate(item.dateCreated).getTime() === today.getTime()
        )
        .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)); // Sort in descending order

      let notTodaysDates = data
        .filter(
          (item) => getLocalDate(item.dateCreated).getTime() !== today.getTime()
        )
        .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)); // Sort in descending order

      setToday(todaysDates);
      setLastDays(notTodaysDates);
    }
  };

  useEffect(() => {
    getData();
  }, [data]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 991.98);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getThreadIDData = async (tID) => {
    const resp = await axios.get(
      `${backendUrl}/api/ChatMessages/Thread/${tID}`
    );

    setThreadIDFunction(tID);

    // const demoImg = resp.data.map((data) => {
    //   let obj;

    //   if (data.hasOwnProperty("documentId")) {
    //     obj = {
    //       ...data,
    //       flag: 1,
    //       location: data.document.location,
    //     };
    //   } else {
    //     obj = {
    //       ...data,
    //       flag: 0,
    //     };
    //   }
    //   return obj;
    // });
    setDisplayDataFunction(resp.data);
    //setDisplayDataFunction(demoImg);
    setDemoImageFuntion(false);
  };

  const handleEdit = async (event, id) => {
    try {
      event.preventDefault();
      const formData = new FormData();
      formData.append("title", editText);

      const res = await axios.put(
        `${backendUrl}/api/ChatThread/${id}`,
        formData
      );

      setData((prevData) => {
        const updatedData = prevData.map((d) => {
          if (d.id === id) {
            return { ...d, title: editText };
          }
          return d;
        });
        return updatedData;
      });
    } catch (error) {
      console.log("Error:-", error);
    } finally {
      setLoadingFunction(false);
    }
  };

  const handleGlobalIconClick = async (id) => {
    try {
      const formData = new FormData();
      formData.append("IsGlobal", true);

      const res = await axios.put(
        `${backendUrl}/api/ChatThread/${id}`,
        formData
      );

      if (res.status === 200) {
        setClickedId(id);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderFunction = (data, isActive, handleEditFunc) => {
    return data.map((d) => {
      return (
        <Nav.Link
          className={`hover-effect sub-heading d-flex align-items-center opacity-100 ${
            d.id === activeLink ? "active-link" : ""
          }`}
          key={d.id}
          onClick={() => {
            getThreadIDData(d.id);
            setActiveLink(d.id);
            localStorage.setItem("isOpen", false);
          }}
          style={{
            overflowX: "hidden",
            width: "100%",
            padding: "0px !important",
          }}
        >
          <div
            style={{
              alignItems: "center",
              position: "relative",
              flexGrow: 1,
              paddingRight: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <RxChatBubble /> &nbsp; &nbsp;
            {d.id === activeLink && isEditing ? (
              <>
                <Form.Control
                  type="text"
                  size="sm"
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  onBlur={() => {
                    if (saveClicked) {
                      setIsEditing(true);
                    } else {
                      setSaveClicked(false);
                    }
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    color: "white",
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <BiCheck
                  className="icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditFunc(e, d.id);
                    setIsEditing(false);
                    setEditText("");
                  }}
                />{" "}
                &nbsp;
                <RxCross2
                  className="icon"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                />
              </>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{d.title}</Tooltip>}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                      flex: 1,
                      height: "1.5rem",

                      fontFamily: "sans-serif",
                    }}
                  >
                    {/* {d.name} */}
                    {d.title}
                  </div>

                  {d.id === activeLink && !isEditing && (
                    <>
                      <div
                        className="active-link-position"
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <AiOutlineEdit
                          onClick={() => {
                            setIsEditing(true);
                            setEditText(d.title);
                          }}
                        />
                        {/* <FiShare /> */}
                        <AiOutlineGlobal
                          style={{
                            color:
                              d.id === clickedId || d.isGlobal ? "green" : "",
                          }}
                          onClick={() => handleGlobalIconClick(d.id)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </OverlayTrigger>
            )}
          </div>
        </Nav.Link>
      );
    });
  };

  const todayFunction = renderFunction(today, activeLink, handleEdit);
  const lastFuntion = renderFunction(lastDays, activeLink, handleEdit);

  const logout = () => {
    // console.log("User:-", user);
    sessionStorage.removeItem("currentClient");
    const logoutRequest = {
      account: publicClientApplication.getAccountByUsername(
        user.userAccount.username
      ),
    };
    publicClientApplication.logoutRedirect(logoutRequest);
    sessionStorage.clear();
    localStorage.removeItem("buttonClick");
    window.location.reload();
  };

  return (
    <div className="sidebar-container ">
      {loading ? (
        <div className="sidebarerror-style-loading">
          <Loader />
        </div>
      ) : (
        <div className="scrolling">
          {sidebarError ? (
            <div className="sidebarerror-style-loading">
              <p>Error Occured</p>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
              >
                Try Again
              </Button>
            </div>
          ) : (
            <>
              <Navbar
                ref={sidebarRef}
                // variant="dark"
                expand={`${
                  localStorage.getItem("isOpen") === "true" ? true : false
                }`}
                // className={`sidebar ${isOpen ? "open" : ""}`}
              >
                {/* <Navbar.Toggle
                  aria-controls="sidebar-nav"
                  onClick={toggleSidebar}
                  className="sidebar-toggle"
                /> */}
                {/* <Navbar.Collapse 
                id="sidebar-nav" className={`${isOpen ? "show" : ""}`}> */}
                <Nav
                  className="flex-column"
                  style={{
                    width: "100%",
                    height: "98vh",
                  }}
                >
                  <img src={InfraGenie_ai} alt="InfraGenie" height="50px" />
                  <Button
                    variant="outline-light"
                    className="custom-button no-hover"
                    // style={{ height: "6vh" }}
                    style={{ color: "white" }}
                    // block
                    block="true"
                    onClick={(e) => {
                      setDisplayDataFunction([]);
                      setDemoImageFuntion(false);
                      setThreadIDFunction(null);
                      setImageCall(false);
                      setActiveLink(null);
                    }}
                  >
                    <img
                      src={Genie}
                      alt="genieimage"
                      style={{ height: "3vh" }}
                    />{" "}
                    &nbsp; New Wish
                  </Button>
                  <div
                    style={{
                      height: "95vh",
                      overflowY: "scroll",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {today.length > 0 && (
                      <>
                        {" "}
                        <h6 className="heading">Today</h6>
                        {todayFunction}
                      </>
                    )}
                    {lastFuntion.length > 0 && (
                      <>
                        <h6 className="heading">Last 7 Days</h6>
                        {lastFuntion}
                      </>
                    )}
                  </div>

                  <DropdownButton
                    id="username"
                    variant="secondary"
                    title={user.userAccount.name}
                    className="mt-2 custom-dropdown no-caret"
                    data-bs-theme="dark"
                    drop="up"
                  >
                    <Dropdown.Item
                      href="/support"
                      target="_blank"
                      className="custom-dropdown-item"
                    >
                      Support
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/privacypolicy"
                      target="_blank"
                      className="custom-dropdown-item"
                    >
                      Privacy Policy
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="custom-dropdown-item"
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      Settings
                    </Dropdown.Item>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />

                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="custom-dropdown-item"
                      onClick={() => {
                        logout();
                      }}
                      // active
                    >
                      Log out
                    </Dropdown.Item>
                  </DropdownButton>
                  <div className="company-div mt-2">
                    <img
                      src={rhipheus_logo}
                      alt="InfraGenie"
                      height="70px"
                      // width="50px"
                    />
                  </div>
                </Nav>
                {/* </Navbar.Collapse> */}
              </Navbar>
            </>
          )}
        </div>
      )}
      {/* <div className="sidebar-fixed"></div> */}
    </div>
  );
};

export default Sidebar;
