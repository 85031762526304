import React, { useState, useEffect, useContext, useMemo } from "react";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import InfraGenie_ai1 from "../assets/InfraGenie_aiSmall.svg";
import "./Login.css";
import MicrosoftLog from "../assets/MicrosoftLog.png";
import { config } from "../config";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import MyContext from "../auth/MyContext";
import { ThreeDots } from "react-loader-spinner";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { user, setSignedInUser, theme, clientId, firstRun, backendUrl } =
    useContext(MyContext);
  const from = location.state?.from?.pathname || "/dashboard";

  const navigate = useNavigate();
  // console.log("login useEffect outside-", clientId);

  const publicClientApplication = useMemo(() => {
    //if (!appData || !appData.apP_CLIENT_ID) {
    //  return null;
    //  }
    // console.log("clientID:-", clientId)
    if (!clientId) {
      // console.log("if (!clientId) {");
      return null;
    }
    return new PublicClientApplication({
      auth: {
        // appData.apP_CLIENT_ID    clientId
        clientId: clientId,
        redirectUri: window.location.href,
        authority: config.authority,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
    });
  }, [clientId]);

  const loginRequest = {
    scopes: ["User.Read", "openid", "profile"],
    prompt: "consent",
  };

  useEffect(() => {
    if (publicClientApplication) {
      handleLogin();
    }
  }, [publicClientApplication]);

  useEffect(() => {
    /*  setTimeout(() => {
      handleLogin();
    }, 1000);  */

    if (user) {
      navigate(from, { replace: true });
    }
  }, []);
  const login = () => {
    if (!publicClientApplication) {
      console.error("The MSAL instance is not initialized.");
      return;
    }
    publicClientApplication.loginRedirect(loginRequest);
    sessionStorage.setItem("buttonClick", true);
  };

  const handleLogin = async () => {
    let accounts, accessToken, account;
    if (sessionStorage.getItem("buttonClick") === "true") {
      setLoading(true);
    }

    publicClientApplication
      .handleRedirectPromise()
      .then(async (response) => {
        if (!response) {
          setLoading(false);
          return;
        }
        accounts = publicClientApplication.getAllAccounts();

        account = accounts.length > 0 ? accounts[0] : null;

        const tokenResponse = await publicClientApplication.acquireTokenSilent({
          ...loginRequest,
          account,
          scopes: ["https://management.azure.com//.default"],
        });

        const subscriptionUrl =
          "https://management.azure.com/subscriptions?api-version=2020-01-01";
        const subscriptionsResponse = await axios.get(subscriptionUrl, {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
        });
        const subscriptions = subscriptionsResponse.data.value;
        // console.log("subscriptions:-", subscriptions);
        const res = await axios.get(`${backendUrl}/api/Configuration`);
        setSignedInUser({
          isAuthenticated: true,
          userAccount: account,
          accessToken: accessToken,
          subscriptions: subscriptions,
          uniqueId: response.uniqueId,
          tenantId: response.tenantId,
        });
        console.log("response.uniqueId:-", response.uniqueId);

        console.log("res login:-", res);
        setLoading(false);
        sessionStorage.setItem("buttonClick", false);
        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error Login Redirect:-", error);
        console.log("Error ****:-", error.code);
        if (error.code === "ERR_BAD_REQUEST") {
          navigate("/error");
        } else {
          if (error instanceof InteractionRequiredAuthError) {
            publicClientApplication.acquireTokenRedirect({
              ...loginRequest,
              scopes: ["https://management.azure.com//.default"],
            });
          } else if (error.errorCode === "interaction_in_progress") {
            setTimeout(() => {
              publicClientApplication
                .acquireTokenSilent({
                  ...loginRequest,
                  account: user.userAccount,
                  scopes: ["https://management.azure.com//.default"],
                })
                .then((response) => {
                  console.log(
                    "Response from silent token acquisition: ",
                    response
                  );
                })
                .catch((err) => {
                  console.error("Error from silent token acquisition: ", err);
                  sessionStorage.removeItem("buttonClick");
                });
            }, 3000);
          } else if (
            error.errorCode &&
            error.errorCode.trim() === "InvalidAuthenticationTokenTenant"
          ) {
            console.log("InvalidAuthenticationTokenTenant");
            navigate("/loginError");
          } else if (
            error.errorCode === "insufficient_privileges" ||
            error.errorCode === "AccessDenied" ||
            error.errorCode === "Unauthorized"
          ) {
            console.log("error.errorCode");
            setSignedInUser({
              isAuthenticated: true,
              userAccount: account,
              // accessToken: accessToken,
              subscriptions: [],
            });
            setLoading(false);
            sessionStorage.setItem("buttonClick", false);
            navigate("/dashboard");
          } else {
            setLoading(false);
            navigate("/loginError");
          }
        }
      });
  };

  return (
    <>
      <div className={`App ${theme === "Dark Mode" ? "dark-theme" : ""}`}>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Card
            className={`p-4  border-0 custom-card  ${
              theme === "Dark Mode" ? "dark-theme" : ""
            }`}
          >
            <Row>
              <Col lg="12" md="" sm="12">
                <img src={InfraGenie_ai1} alt="InfraGenie" height="100px" />
                <h3
                  className={`"mt-5" ${
                    theme === "Dark Mode" ? "dark-font-style" : ""
                  }`}
                >
                  {/* {firstRun ? "Welcome Back" : "Welcome"} */}
                  Welcome
                </h3>
              </Col>
            </Row>
            <Row>
              <Form>
                <Button
                  variant="outline-secondary"
                  className={`w-100 mb-3 ${
                    theme === "Dark Mode" ? "dark-font-style" : ""
                  }`}
                  onClick={async (e) => {
                    e.preventDefault();
                    login();
                  }}
                  disabled={loading}
                >
                  <img
                    src={MicrosoftLog}
                    alt="Microsoft Logo"
                    className="me-2"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Continue with Microsoft
                </Button>
                {loading && (
                  <div className="d-flex justify-content-center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="grey"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
                {}
              </Form>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;
