import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "./LandingPage.css";
import InfraGenie_ai from "../assets/InfraGenie.svg";
import Genie_Img from "../assets/LandingImg.png";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const minWidth = 800;
  const minHeight = 600;
  const handleResize = () => {
    setWindowSize({
      width: Math.max(window.innerWidth, minWidth),
      height: Math.max(window.innerHeight, minHeight),
    });
  };

  useEffect(() => {
    // Set initial window size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="background-container">
      <Container fluid className="p-0">
        {/* Header Section */}
        <div className="pt-5">
          <Row className="align-items-center px-2 py-3 mx-4 mt-0  bg-white header-border">
            <Col>
              <Image src={InfraGenie_ai} className="logo" alt="Logo" />
            </Col>
            <Col xs="auto" className="ml-auto">
              <Button
                variant="outline-primary"
                className="btn-lg button-font  pl-0"
                size="xlg"
                style={{ borderRadius: "30px" }}
                onClick={(e) => {
                  navigate("/signUp");
                }}
              >
                Sign Up
              </Button>
            </Col>
          </Row>
        </div>

        {/* Content Section */}
        <Row className="mt-3 px-3">
          <Col xs={8} className="content-padding">
            <p className="text-powered">Powered by</p>
            <p className="text-genie">Genie.ai</p>
            <p className="text-description">Your very own AI Genie</p>
            <p className="text-details">
              Deploy & Update <br /> Azure Infrastructure in minutes
            </p>
            <Button
              variant="primary"
              className="small-primary-button"
              size="lg"
              onClick={(e) => {
                navigate("/login");
              }}
            >
              Log In
            </Button>
          </Col>
        </Row>
      </Container>
      <Image
        src={Genie_Img}
        className="position-fixed bottom-right-image"
        width={150}
        alt="Another Image"
      />
    </div>
  );
};

export default LandingPage;
