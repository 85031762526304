import React, { useContext, useState, useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LiaEdit } from "react-icons/lia";
import userlogo from "../assets/user.png";
import genieF from "../assets/GenieF.svg";
import { ThreeDots } from "react-loader-spinner";
import SyntaxHighlighter from "react-syntax-highlighter";
import hljs from "highlight.js";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import MyContext from "../auth/MyContext";

const ImageOrDocument = ({ imgPath }) => {
  const fileExtension = imgPath.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
    return (
      <img
        src={imgPath}
        alt="userimage"
        // style={{ width: "100%", height: "auto" }}
      />
    );
  }
  if (["doc", "docx", "pdf", "txt"].includes(fileExtension)) {
    return (
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          imgPath
        )}&embedded=true`}
        style={{ width: "100%", height: "500px" }}
        title="Doc preview"
      ></iframe>
    );
  }
  return null;
};

const EditableArea = ({
  editValue,
  setEditValue,
  handleSave,
  handleCancel,
}) => (
  <div>
    <textarea
      value={editValue}
      onChange={(e) => setEditValue(e.target.value)}
      style={{ width: "100%", border: "none" }}
    />
    <div>
      <Button onClick={handleSave} className="button-style" variant="success">
        Save & Submit
      </Button>
      <Button
        onClick={handleCancel}
        className="button-style"
        variant="outline-dark"
      >
        Cancel
      </Button>
    </div>
  </div>
);

const DisplayDataComponent = ({
  displayData,
  editingIndex,
  editValue,
  setEditValue,
  handleSave,
  handleCancel,
  theme,
  demoImage,
  handleToggleEdit,
  loading,
  imageCall,
}) => {
  const { backendUrl, threadID, setDeploymentStatusFunction } =
    useContext(MyContext);
  const [dCount, setDCount] = useState(0);
  const jsonExRef = useRef(null);
  const [toCallPostCLI, setToCallPostCLI] = useState(null); // This will store jsonEx when it needs to be processed

  useEffect(() => {
    if (toCallPostCLI) {
      callPostCLI(toCallPostCLI);
      setToCallPostCLI(null); // Reset after calling to avoid multiple calls
    }
  }, [toCallPostCLI]);

  const callPostCLI = async (jsonEx) => {
    try {
      if (dCount === 0) {
        // console.log("DCount:-", dCount);
        const jsonObject = JSON.parse(jsonEx);
        setDeploymentStatusFunction(true);
        const obj = {
          request: "genie",
          command: "prep",
          parameters: [
            {
              name: "--folder-name",
              value: jsonObject.projectName,
            },
            {
              name: "--project-name",
              value: jsonObject.projectName,
            },
          ],
          ThreadId: threadID,
        };
        // console.log("obj:-", obj);
        const resCLI = await axios.post(`${backendUrl}/api/Execute`, obj);
        // console.log("resPrep:-", resCLI);
        const objWish = {
          request: "genie",
          command: "wish",
          parameters: [
            {
              name: "--folder-name",
              value: jsonObject.projectName,
            },
            {
              name: "--project-name",
              value: jsonObject.projectName,
            },
            {
              name: "--component",
              value: "api,web,DB",
            },
            {
              name: "--services",
              value: "app-service-plan,app-service,database",
            },
            {
              name: "--mode",
              value: "isolated",
            },
          ],
          threadId: threadID,
        };
        const resCLIWish = await axios.post(
          `${backendUrl}/api/Execute`,
          objWish
        );
        // console.log("resCLIWish:-", resCLIWish);
        setDCount(1);
      }
    } catch (error) {
      console.log("Error in Execute:-", error);
    }
  };

  return (
    <>
      {/* {console.log("display old:-", displayData)} */}
      {displayData.map((data, index) => {
        let seg = "";
        let modstring = "";

        const { json: extractedJson, modifiedResponse } = extractAndRemoveJson(
          data.response
        );
        // console.log("extractedJson:-", extractedJson);
        // console.log("modifiedResponse:-", modifiedResponse);

        if (extractedJson && imageCall) {
          // const jsonEx = extractValidJson(data.response);
          modstring = modifiedResponse;
          const str = `Thank you for providing the information. This is the ThreadId:-${threadID} for your instance, conveniently accessible on the Azure Portal for the deployment.`;
          modstring = str;
          // if (!toCallPostCLI) setToCallPostCLI(jsonEx);
        } else {
          modstring = data.response;
        }

        // if (
        //   data.response.includes("Brokers") &&
        //   containsValidJson(data.response)
        // ) {
        //   const str = `Thank you for providing the information. This is the ThreadId:-${threadID} for your instance, conveniently accessible on the Azure Portal for the deployment.`;
        //   modstring = str;
        //   console.log("******Brokers");
        // }

        const imgPath = data.img || data.location;
        return (
          <>
            <Row key={`${index}-request`} className={`card-width-style`}>
              <Col xs="auto">
                <img
                  src={userlogo}
                  alt="User"
                  style={{ width: "25px", height: "25px" }}
                />
              </Col>
              <Col>
                {index === editingIndex ? (
                  <EditableArea
                    editValue={editValue}
                    setEditValue={setEditValue}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                  />
                ) : data.flag === 1 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      overflowY: "scroll",
                    }}
                  >
                    <p
                      className={`text-css ${
                        theme === "Dark Mode" ? "dark-theme-color" : "black"
                      }`}
                    >
                      {!demoImage && data.request}
                    </p>
                    <ImageOrDocument imgPath={imgPath} />

                    <br />
                  </div>
                ) : (
                  <p
                    className={`"text-css" ${theme}==="Dark Mode" ? "dark-theme-color" : "black"`}
                    style={{ color: theme === "Dark Mode" ? "white" : "" }}
                  >
                    {data.request}
                  </p>
                )}
              </Col>
              <Col
                xs="auto"
                style={{ cursor: "pointer" }}
                onClick={() => handleToggleEdit(index)}
              >
                <LiaEdit
                  style={{ color: theme === "Light Mode" ? "" : "white" }}
                />
              </Col>
            </Row>
            <Row
              key={`${index}-response`}
              className={`card-width-style ${
                theme === "Dark Mode"
                  ? "card-background-color-infra-dark"
                  : "card-background-color-infra"
              }`}
            >
              <Col xs="auto">
                <img
                  src={genieF}
                  alt="genie"
                  style={{ width: "25px", height: "25px" }}
                />
              </Col>
              <Col
                style={{
                  border: data.flag === 2 ? "#edd5db solid 2px" : "none",
                  borderRadius: "10px",
                  backgroundColor: data.flag === 2 ? "#edd5db" : "none",
                }}
              >
                {loading && data.response === "" ? (
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="grey"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <>
                    {/* data.response */}
                    {modstring.split("```").map((segment, index) => {
                      if (index % 2 === 0) {
                        // It's regular text
                        return segment.split("\n").map((line, lineIndex) => {
                          if (line.trim() === "") return null; // Avoid rendering empty lines
                          return (
                            // <React.Fragment key={`${index}-${lineIndex}`}>
                            //   <p>{line}</p>
                            // </React.Fragment>
                            <ReactMarkdown key={`${index}-${lineIndex}`}>
                              {line}
                            </ReactMarkdown>
                          );
                        });
                      } else {
                        seg = seg + segment;
                      }
                    })}
                    {/* {seg !== "" && (
                      <div
                        key={index}
                        style={{
                          margin: "10px 0",
                          padding: "10px",
                          backgroundColor: "black",
                        }}
                      >
                        <SyntaxHighlighter
                          language={(() => {
                            let language;
                            try {
                              language = hljs.highlightAuto(seg).language;
                            } catch (e) {
                              language = ""; // default to no specific language if detection fails
                            }

                            return language || "javascript";
                          })()}
                          style={atomOneDark}
                          customStyle={{ backgroundColor: "black" }} // Here's the added prop
                        >
                          {seg}
                        </SyntaxHighlighter>
                      </div>
                    )} */}
                    {seg && (
                      <div
                        style={{
                          margin: "10px 0",
                          padding: "10px",
                          backgroundColor: "black",
                        }}
                      >
                        <SyntaxHighlighter
                          language={determineLanguage(seg)}
                          style={atomOneDark}
                          customStyle={{ backgroundColor: "black" }}
                        >
                          {seg}
                        </SyntaxHighlighter>

                        {/* <SyntaxHighlighter
                          language={determineLanguage(seg)}
                          style={atomOneDark}
                          customStyle={{ backgroundColor: "black" }}
                        >
                          {seg}
                        </SyntaxHighlighter> */}
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      })}
    </>
  );
};

function determineLanguage(segment) {
  let language;
  try {
    language = hljs.highlightAuto(segment).language;
  } catch (e) {
    language = ""; // default to no specific language if detection fails
  }
  return language || "javascript";
}

function isJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function containsValidJson(str) {
  let startIndex = str.indexOf("{");
  while (startIndex > -1) {
    let braceCount = 0;
    for (let i = startIndex; i < str.length; i++) {
      if (str[i] === "{") {
        braceCount++;
      } else if (str[i] === "}") {
        braceCount--;
        if (braceCount === 0) {
          // Found a potential JSON substring
          const potentialJson = str.substring(startIndex, i + 1);
          try {
            JSON.parse(potentialJson);
            return true;
          } catch (e) {
            // Not a valid JSON, continue searching
            break;
          }
        }
      }
    }
    // Search for next '{'
    startIndex = str.indexOf("{", startIndex + 1);
  }
  return false;
}

function extractAndRemoveJson(str) {
  let startIndex = str.indexOf("{");
  let detectedJson = null;

  while (startIndex > -1) {
    let braceCount = 0;
    for (let i = startIndex; i < str.length; i++) {
      if (str[i] === "{") {
        braceCount++;
      } else if (str[i] === "}") {
        braceCount--;
        if (braceCount === 0) {
          // Found a potential JSON substring
          const potentialJson = str.substring(startIndex, i + 1);
          try {
            JSON.parse(potentialJson);
            detectedJson = potentialJson;
            str = str.replace(detectedJson, "").trim(); // Removing detected JSON from response and trimming whitespace
            break; // Breaking as we've modified the string and indices may not be valid anymore
          } catch (e) {
            // Not a valid JSON, continue searching
            break;
          }
        }
      }
    }
    startIndex = str.indexOf("{", startIndex + 1);
  }
  return { json: detectedJson, modifiedResponse: str };
}

function extractValidJson(str) {
  let startIndex = str.indexOf("{");
  while (startIndex > -1) {
    let braceCount = 0;
    for (let i = startIndex; i < str.length; i++) {
      if (str[i] === "{") {
        braceCount++;
      } else if (str[i] === "}") {
        braceCount--;
        if (braceCount === 0) {
          // Found a potential JSON substring
          const potentialJson = str.substring(startIndex, i + 1);
          try {
            JSON.parse(potentialJson);
            return potentialJson; // Return the valid JSON substring
          } catch (e) {
            // Not a valid JSON, continue searching
            break;
          }
        }
      }
    }
    // Search for next '{'
    startIndex = str.indexOf("{", startIndex + 1);
  }
  return null; // Return null if no valid JSON substring is found
}

export default DisplayDataComponent;
