import React from "react";
import { Col, Row } from "react-bootstrap";
import Correct from "../assets/right.jpg";

const DeploymentCompletedCard = () => {
  return (
    <Row>
      <Col
        lg="3"
        style={{
          background: "#f7f7f7",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          marginLeft: "15px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={Correct}
            alt="right img"
            style={{
              width: "40px",
              height: "40px",
              marginTop: "5px",
            }}
          />
          &nbsp;
          <span> Deployment Complete</span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            alignSelf: "stretch",
            marginTop: "-10px",
          }}
        >
          <a
            href="https://portal.azure.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Azure Portal
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default DeploymentCompletedCard;
