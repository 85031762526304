// ResizableSidebar.js
import React, { useState, useEffect } from "react";
import "./ResizableSidebar.css";

const ResizableSidebar = ({ sidebar, mainContent, theme }) => {
  const [width, setWidth] = useState(300);
  const [isResizing, setIsResizing] = useState(false);
  const minWidth = 190;
  const maxWidth = 730;

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isResizing) {
        // const newWidth = event.clientX;
        //const newWidth = Math.max(event.clientX, minWidth);
        const newWidth = Math.min(Math.max(event.clientX, minWidth), maxWidth);

        setWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className="resizable-sidebar-container">
      <div className="resizable-sidebar" style={{ width: `${width}px` }}>
        {sidebar}
      </div>
      <div
        className="resizer"
        style={{ backgroundColor: theme === "Dark Mode" ? "#343a40" : "" }}
        onMouseDown={() => setIsResizing(true)}
      ></div>
      <div
        className={`resizable-content ${
          theme === "Dark Mode" ? "dark-mode" : "light-mode"
        }`}
      >
        {mainContent}
      </div>
    </div>
  );
};

export default ResizableSidebar;
