import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Dashboard1 from "./Dashboard1";
import "./MainDashboard.css";
import ResizableSidebar from "./ResizableSidebar";
import MyContext from "../auth/MyContext";
import FirstRunModal from "./FirstRunModal";
import FirstRunModelDemo from "./FirstRunModelDemo";
import ReactMarkdown from "react-markdown";
import hljs from "highlight.js";

const MainDashboard = () => {
  const { theme, setThemeFunction, firstRun, setFirstRun } =
    useContext(MyContext);
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [device, setDevice] = useState("desktop");
  const [modalShow, setModalShow] = React.useState(true);

  useEffect(() => {
    localStorage.setItem("isOpen", false);
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        setDevice("mobile");
      } else {
        setDevice("desktop");
      }
    };

    // Initial check
    handleResize();

    // Set up event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
      localStorage.setItem("isOpen", false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const setModalShowFunction = (value) => {
  //   setModalShow(value);
  // };

  // useEffect(() => {
  //   const modstring =
  //     "for num in range(1, 101):   if num > 1:       for i in range(2, num):           if (num % i) == 0:               break       else:print(num)";
  //   let seg = "";

  //   modstring.split("```").map((segment, index) => {
  //     if (index % 2 === 0) {
  //       // It's regular text
  //       return segment.split("\n").map((line, lineIndex) => {
  //         if (line.trim() === "") return null; // Avoid rendering empty lines
  //         return (
  //           // <React.Fragment key={`${index}-${lineIndex}`}>
  //           //   <p>{line}</p>
  //           // </React.Fragment>
  //           <ReactMarkdown key={`${index}-${lineIndex}`}>{line}</ReactMarkdown>
  //         );
  //       });
  //     } else {
  //       seg = seg + segment;
  //     }
  //   });
  //   console.log("seg:-", seg);
  //   function isCode(text) {
  //     // Basic check for length and complexity
  //     if (
  //       text.length < 50 ||
  //       (!text.includes(";") && !text.includes("{") && !text.includes("}"))
  //     ) {
  //       return false;
  //     }

  //     const result = hljs.highlightAuto(text);
  //     return result.relevance > 10; // Adjusted relevance threshold
  //   }
  //   let iSCodeResult = isCode(modstring);
  //   console.log("iSCodeResult:-", iSCodeResult);
  // }, []);

  useEffect(() => {
    setThemeFunction(localStorage.getItem("mode"));
  }, [theme]);
  useEffect(() => {
    // if (firstRun) {
    //   setModalShow(true);
    // } else {
    //   // setModalShow(false);
    // }
    setModalShow(true);
    // console.log("firstrun:-", firstRun);
  }, [firstRun]);

  return (
    <Container fluid className="conatiner-style">
      {device === "desktop" ? (
        <Row className="row-height">
          <Col lg="12" style={{ padding: "0px" }}>
            <ResizableSidebar
              sidebar={<Sidebar />}
              mainContent={<Dashboard1 />}
              theme={theme}
            />
          </Col>
        </Row>
      ) : (
        <div>
          <div className="mobile-header">
            <div
              className="burger-menu"
              onClick={() => {
                setIsOpen(!isOpen);
                localStorage.setItem("isOpen", !isOpen);
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div>
            {localStorage.getItem("isOpen") === "true" && <Sidebar />}
            {localStorage.getItem("isOpen") === "false" && (
              <div
                className={theme === "Dark Mode" ? "dark-mode" : "light-mode"}
              >
                {" "}
                <Dashboard1 />
              </div>
            )}
          </div>
        </div>
      )}

      {/* {firstRun && (
        <FirstRunModal modalShow={modalShow} setModalShow={setModalShow} />
      )} */}
    </Container>
  );
};

export default MainDashboard;
