import React from "react";
import { Card, Col, Form, Row, Button, Image } from "react-bootstrap";
import "./Signup.css";
import InfraGenie_ai from "../assets/InfraGenie_aiSmall.svg";

const Signup = () => {
  return (
    <div className="background-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Col xs={12} md={8} lg={6} xl={4}>
          <div className="d-flex justify-content-center ">
            <Image src={InfraGenie_ai} style={{ width: "50%" }} />
          </div>
          <Card>
            <Card.Body className="custom-card-body p-4 ">
              <Card.Title className="center-title">
                Create an Infragenie account
              </Card.Title>
              <Form>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label className="mb-0 label-font">
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    name="firstName"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Label className="mb-0 label-font">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="displayName">
                  <Form.Label className="mb-0 label-font">
                    Display Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Display name"
                    name="displayName"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="emailId">
                  <Form.Label className="mb-0 label-font">
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="companyName">
                  <Form.Label className="mb-0 label-font">
                    Company Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="companyName"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="domain">
                  <Form.Label className="mb-0 label-font">Domain</Form.Label>
                  <Form.Control type="text" placeholder="Domain" />
                </Form.Group>
                <Button variant="primary" className="w-100 ">
                  Create account
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default Signup;
