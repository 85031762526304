import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import warning from "../assets/warningimage.png";
const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f0f4fa",
      }}
    >
      <Row>
        <Col>
          <Image src={warning} style={{ width: "50%" }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Sorry</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>It seems OpenAI installation has failed. </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            You must have received an email from InfraGenie with more
            information.
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          You may have to &nbsp;
          <a
            href="https://azure.microsoft.com/en-us/products/ai-services/openai-service/?ef_id=_k_CjwKCAiApuCrBhAuEiwA8VJ6Jss3vf5FzrMRl8PwNSdujmVtK2vZoghGNe0AxjJDHRHKHodAFoQ4bxoC2_4QAvD_BwE_k_&OCID=AIDcmm5edswduu_SEM__k_CjwKCAiApuCrBhAuEiwA8VJ6Jss3vf5FzrMRl8PwNSdujmVtK2vZoghGNe0AxjJDHRHKHodAFoQ4bxoC2_4QAvD_BwE"
            target="_blank"
            rel="noreferrer"
          >
            request Azure OpenAI access
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorPage;
